import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const ContactPage = () => {
    return (
        <div>
            <Layout>
                <Head title="Contact"/>
            <h1>Contact Us</h1>
            <p>Considering there's no one to contact, any information here would prove a real waste of time.</p>
            <p>... but, if you'd like to follow me on Twitter... who am I to deny you? <a href="https://twitter.com/Kevin_Cash" target="_blank">@KevinCash</a></p>
            </Layout>
        </div>
    )
}

export default ContactPage